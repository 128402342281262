'use client';
import { useEffect, useRef, useState } from 'react';
const GlobalMusicPlayer = ({ src }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        const handleUserInteraction = () => {
            audioRef.current.muted = false;
            window.removeEventListener('click', handleUserInteraction);
        };

        window.addEventListener('click', handleUserInteraction);

        return () => {
            window.removeEventListener('click', handleUserInteraction);
        };
    }, []);

    return (
        <div className="music-player">
            <audio ref={audioRef} src={src} loop autoPlay muted />
            <button onClick={togglePlay} className="play-button">
                {isPlaying ? 'Pause' : 'Play'}
            </button>
            <style jsx>{`
                .music-player {
                  position: fixed;
                  bottom: 20px;
                  right: 20px;
                  background: rgba(0, 0, 0, 0.5);
                  border-radius: 50px;
                  padding: 10px 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 1000;
                }
                .play-button {
                  background: none;
                  border: none;
                  color: white;
                  font-size: 16px;
                  cursor: pointer;
                  outline: none;
                }
            `}
            </style>
        </div>
    );
};

export default GlobalMusicPlayer;
